<template>
  <div class="index">
      <div class="nav_list">
          <div class="nav_item" v-for="(item,index) in nav_list" :key="index" @click="clickNav(item)">
            <img class="icon" :src="item.icon" alt="">
            <p class="title">{{item.title}}</p>
          </div>
      </div>
      <div class="notify">
        <div class="left">
          <img src="../../../static/img/notice.png" alt="" class="notice">
          <span>公告：</span>
        </div>
        <div class="right">
          <NoticeBar :text="noticetext" background="#fff" color="#000"/>
        </div>
      </div>
      <div class="banner" @click="$router.push('/leaderboard')">
        <img src="../../../static/img/order-list.png" alt="">
      </div>
      <div class="bank_list">
        <div class="title">合作机构</div>
        <ul>
          <li v-for="(item,index) in bank_list" :key="index">
            <img :src="item.icon" class="card" alt="">
            <p class="name">{{item.text}}</p>
          </li>
        </ul>
      </div>
  </div>
</template>

<script>

import { NoticeBar } from 'vant';

export default {
  name: "index",
  props: {},
  components: {
    NoticeBar
  },
  mounted() {
    this.getIndexInfo()
  },
  data() {
    return {
      noticetext: '',
      kefu: '',
      nav_list: [
        {
          id: '',
          path: '/announcement',
          title: '公告',
          icon: require('/static/img/index_icon01.png'),
        },
        {
          id: 3,
          path: '/newdetails',
          title: '平台简介',
          icon: require('/static/img/index_icon02.png'),
        },
        {
          id: 1,
          path: '/platformRewar',
          title: '平台奖励',
          icon: require('/static/img/index_icon03.png'),
        },
        {
          id: 1,
          path: '/beginnerSGuide',
          title: '新手指引',
          icon: require('/static/img/index_icon04.png'),
        },
        {
          id: 1,
          path: '/Information',
          title: '资讯',
          icon: require('/static/img/index_icon05.png'),
        },
        {
          id: 1,
          path: '/Security',
          title: '安全保障',
          icon: require('/static/img/index_icon06.png'),
        },
        {
          type: 'kefu',
          id: '',
          path: '',
          title: '在线客服',
          icon: require('/static/img/index_icon07.png'),
        },
        {
          id: 1,
          path: '/MoreFeatures',
          title: '更多功能',
          icon: require('/static/img/index_icon08.png'),
        }
      ],
      bank_list: [
        {
          icon: require('/static/img/card01.png'),
          text: '浦发银行'
        },
        {
          icon: require('/static/img/card02.png'),
          text: '工商银行'
        },
        {
          icon: require('/static/img/card03.png'),
          text: '民生银行'
        },
        {
          icon: require('/static/img/card04.png'),
          text: '建设银行'
        },
        {
          icon: require('/static/img/card05.png'),
          text: '交通银行'
        },
        {
          icon: require('/static/img/card06.png'),
          text: '中国银联'
        },
        {
          icon: require('/static/img/card07.png'),
          text: '中国邮政'
        },
        {
          icon: require('/static/img/card08.png'),
          text: '中信银行'
        },
      ]
    }
  },
  methods: {
    getIndexInfo(){
      $api.index({
        token: this.$store.getters["getToken"]
      }).then(res => {
        this.noticetext = res.data.gonggao
        this.kefu = res.data.kefu;
        this.$store.commit('setKefuUrl', this.kefu)
      }).catch(err => {

      })
    },
    clickNav(info){
      if (info.type == 'kefu') {
        window.location.href = this.kefu;
        return;
      }
      this.$router.push({
        path: info.path,
        query: {
          id: info.id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.index {
  background: #f7f7fe;
  height: 100%;
  padding-bottom: 35px;
  overflow: auto;
  .nav_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: #fff;
    padding-bottom: 20px;
    .nav_item {
      width: 25%;
      margin-top: 35px;
      font-size: 13px;
      &:nth-child(-n+4) {
        margin-top: 25px;
      }
      .icon {
        width: 34px;
        height: 34px;
        margin-bottom: 15px;
      }
    }
  }
  .notify {
    width: 90%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    background: #fff;
    margin: 20px auto;
    border-radius: 20px;
    .left {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      color: #ffb150;
      .notice {
          width: 17px;
          margin-right: 6px;
      }
    }
    .right {
      width: 80%;
    }
  }
  .banner img {
    width: 90%;
    height: 105px;
    margin: auto;
  }
  .bank_list {
    margin-top: 5px;
    >.title {
      font-size: 17px;
      font-weight: bold;
      line-height: 40px;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        left: 0px;
        right: 0px;
        margin: auto;
        bottom: 0;
        width: 15px;
        height: 4px;
        background: #ffbc5b;
      }
    }
    >ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      li {
        width: 25%;
        margin-top: 20px;
        .card {
          width: 28px;
          height: 28px;
        }
      }
    }
  }
}
</style>